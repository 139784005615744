import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { RequestSettingsKeys } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { BsArrowDownUp, BsClock } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import { RiFlashlightLine } from "react-icons/ri";
import { VscArrowSwap } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { RootState } from "../../../store";
import { useAuthentication } from "../../auth/AuthProvider";
import { UserBadge } from "../../badges/UserBadge";
import { useProductSubscriptions } from "../AppShell";
import { MegaMenu } from "./MegaMenu";
import { ProductMenu } from "./ProductMenu";
import { SwithAccountModal } from "./SwitchAccountModal";

export interface MenuType {
  route: string;
  text: string;
}

export function AccountMenu() {
  const {
    currentUser: user,
    accounts,
    currentAccount,
    isProductAdmin,
    isEventAdmin,
    isInventoryAdmin,
  } = useAuthentication();
  const { hasEvents, hasRequests, hasInventory } = useProductSubscriptions();
  const {
    isAdmin,
    isReviewer,
    isTechnician,
    isContractor,
    accountSettings,
    policyAllowsDashboard,
  } = useSelector((state: RootState) => state.localCache);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const showUserName = useBreakpointValue({ base: false, xl: true });
  const userBadgeBg = useColorModeValue("gray.200", "whiteAlpha.200");
  const iconsColor = useColorModeValue("blue.500", "blue.300");
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });
  const schoolNameColor = useColorModeValue("gray.500", "whiteAlpha.800");

  const handleColorModeChange = () => {
    toggleColorMode();
  };

  const showTransactions = useMemo(
    () => policyAllowsDashboard || isProductAdmin || isAdmin || isReviewer,
    [policyAllowsDashboard, isProductAdmin, isAdmin, isReviewer]
  );

  const showTimesheet = useMemo(
    () =>
      isProductAdmin || isAdmin || isReviewer || isContractor || isTechnician,
    [isProductAdmin, isAdmin, isReviewer, isContractor, isTechnician]
  );

  const count = useMemo(() => {
    let count = 0;
    if (isProductAdmin && hasRequests) count++;
    if (isEventAdmin && hasEvents) count++;
    if (isInventoryAdmin && hasInventory) count++;
    return count;
  }, [
    hasEvents,
    hasRequests,
    isEventAdmin,
    isProductAdmin,
    isInventoryAdmin,
    hasInventory,
  ]);

  const MenuWidth = useMemo(() => {
    if (isMobile) return "200px";

    if (!isProductAdmin && !isEventAdmin && !isInventoryAdmin) {
      return "120px";
    }

    if (count >= 2) return "798px";

    return "500px";
  }, [isEventAdmin, isMobile, isProductAdmin, isInventoryAdmin, count]);

  return (
    <>
      <Menu
        closeOnBlur={true}
        offset={[0, 0]}
        closeOnSelect={true}
        placement="bottom-end"
      >
        {({ onClose, isOpen }) => (
          <>
            <MenuButton
              as={Button}
              bgColor={showUserName ? userBadgeBg : "transparent"}
              rightIcon={showUserName ? <ChevronDownIcon /> : undefined}
              _active={{ bgColor: showUserName ? userBadgeBg : "transparent" }}
              border="none"
              display="flex"
              alignItems="center"
            >
              <UserBadge
                value={user}
                hideName={!showUserName}
                backgroundColor={showUserName ? userBadgeBg : "transparent"}
              />
            </MenuButton>
            {isOpen && (
              <Portal>
                <MenuList zIndex={10} minW={MenuWidth} p={2}>
                  {!isMobile &&
                  (isProductAdmin || isEventAdmin || isInventoryAdmin) ? (
                    <MegaMenu onOpenSwitchModal={onOpen} />
                  ) : (
                    <>
                      <Text px={4} color={schoolNameColor} fontWeight="bold">
                        {currentAccount.name}
                      </Text>
                      <MenuDivider />
                      <MenuItem onClick={handleColorModeChange}>
                        {colorMode === "light" ? (
                          <>
                            <Icon
                              mr={2}
                              as={RiFlashlightLine}
                              color={iconsColor}
                            />
                            <Text>Switch to dark mode</Text>
                          </>
                        ) : (
                          <>
                            <Icon
                              as={RiFlashlightLine}
                              mr={2}
                              color={iconsColor}
                            />
                            <Text>Switch to light mode</Text>
                          </>
                        )}
                      </MenuItem>

                      <MenuItem as={RouterLink} to="/user-settings">
                        <Icon
                          as={IoSettingsOutline}
                          mr={2}
                          color={iconsColor}
                        />
                        <Text>User Profile</Text>
                      </MenuItem>
                      {accounts.length > 1 && (
                        <MenuItem onClick={onOpen}>
                          <Icon as={VscArrowSwap} mr={2} color={iconsColor} />
                          Switch Account
                        </MenuItem>
                      )}
                      {showTransactions && (
                        <MenuItem as={RouterLink} to="/transactions">
                          <Icon as={BsArrowDownUp} mr={1} color={iconsColor} />
                          Transactions
                        </MenuItem>
                      )}

                      {showTimesheet && (
                        <MenuItem as={RouterLink} to="/timesheet">
                          <Icon as={BsClock} mr={1} color={iconsColor} />
                          Timesheet
                        </MenuItem>
                      )}
                      <MenuDivider m={1} />
                      <ProductMenu onClose={onClose} />
                      {Boolean(
                        accountSettings[
                          RequestSettingsKeys.ALLOW_TECHNICIANS_TO_MANAGE_ASSETS
                        ]
                      ) && (
                        <MenuItem as={RouterLink} to={"account/assets"}>
                          Assets
                        </MenuItem>
                      )}
                      <MenuDivider m={1} />
                      <MenuItem
                        as="a"
                        href={`${process.env.REACT_APP_AUTH_SERVER}/logout?redirectUrl=/login?accountId=${currentAccount.id}`}
                      >
                        <Icon as={FiLogOut} mr={2} color={iconsColor} />
                        Logout
                      </MenuItem>
                    </>
                  )}
                </MenuList>
              </Portal>
            )}
          </>
        )}
      </Menu>
      {isOpen && <SwithAccountModal isOpen={isOpen} onClose={onClose} />}
    </>
  );
}
