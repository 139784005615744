import {
  ApiAccount,
  ApiIssuanceTransaction,
  ApiLaborTransaction,
  ApiLocation,
  ApiLocationSummary,
  ApiPurchaseTransaction,
  ApiRequest,
  ApiUserSummary,
  ApiWorkflowField,
  ApiWorkflowFieldDataType,
  SelectionField,
} from "@operations-hero/lib-api-client";
import { Image, Page, Text, View } from "@react-pdf/renderer";
import { useCallback, useMemo } from "react";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { formatCurrency } from "../../../utils/formatCurrency";
import { getFormatedHour } from "../transactions/transactions-helper";
import { PdfStyles } from "./PrintableRequest";

export interface PrintableRequestReportProps {
  request: ApiRequest;
  laborsData: ApiLaborTransaction[];
  purchasesData: ApiPurchaseTransaction[];
  issuanceData: ApiIssuanceTransaction[];
  account: ApiAccount;
  assignees: string;
  singleLineFields: ApiWorkflowField[];
  multilineFields: ApiWorkflowField[];
  totalLaborHours: string;
  hasInventory: boolean;
  ancestors?: ApiLocation[];
}

export const PrintableRequestReport = ({
  request,
  laborsData,
  purchasesData,
  issuanceData,
  account,
  assignees,
  ancestors,
  singleLineFields,
  multilineFields,
  totalLaborHours,
  hasInventory,
}: PrintableRequestReportProps) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    return `${month}-${day}-${year}`;
  };

  const totalLaborCost = useMemo(() => {
    return laborsData.reduce((result, data) => {
      return (result += data.total);
    }, 0);
  }, [laborsData]);

  const totalExpensesCost = useMemo(() => {
    return purchasesData.reduce((result, data) => {
      return (result += data.total);
    }, 0);
  }, [purchasesData]);

  const totalInventoryCost = useMemo(() => {
    return issuanceData.reduce((result, data) => {
      return (result += data.total);
    }, 0);
  }, [issuanceData]);

  const getSingleFieldValue = useCallback(
    (field: ApiWorkflowField) => {
      const { checkbox, date, location, number, selection, text, user } =
        ApiWorkflowFieldDataType;
      const fieldValue = request.metadata[field.key];
      if (!fieldValue) return "";
      switch (field.dataType) {
        case checkbox:
          return "Checked";
        case date:
          return new Date(fieldValue as string).toLocaleString();
        case location:
          return (fieldValue as ApiLocationSummary).name;
        case number:
          return fieldValue.toString();
        case selection:
          return (fieldValue as SelectionField).value;
        case text:
          return fieldValue.toString();
        case user:
          return `${(fieldValue as ApiUserSummary).firstName} ${
            (fieldValue as ApiUserSummary).lastName
          }`;
      }
    },
    [request.metadata]
  );

  return (
    <Page size="A4" style={PdfStyles.page} wrap={true}>
      <View style={PdfStyles.header}>
        <View style={PdfStyles.headerText}>
          <Text style={{ fontSize: 20 }}>{request.key}</Text>
          <View style={PdfStyles.row}>
            <Text style={{ marginTop: 10 }}>
              Priority: {request.priority.toUpperCase()}
            </Text>
            <Text
              style={{
                marginTop: 10,
                marginLeft: 10,
                flexGrow: 1,
              }}
            >
              Type: {request.type.toUpperCase()}
            </Text>
          </View>
        </View>
        <View style={PdfStyles.headerLogo}>
          {!account.logo && (
            <Text style={{ fontSize: 14, textAlign: "right" }}>
              {account.name}
            </Text>
          )}
          {account.logo && (
            <Image
              src={account.logo}
              style={{
                width: "auto",
                height: "auto",
                alignSelf: "flex-end",
              }}
            />
          )}
        </View>
      </View>

      <View>
        <View style={{ marginVertical: 20 }}>
          <Text style={PdfStyles.fieldTitle}>Summary Report</Text>
        </View>
      </View>

      <View style={PdfStyles.fields}>
        <View style={PdfStyles.row}>
          <View style={{ flexGrow: 1, flexDirection: "row" }}>
            <Text style={PdfStyles.fieldLabel}>Requested By:</Text>
            <Text style={PdfStyles.fieldValue}>
              {`${request.requester.firstName} ${request.requester.lastName}`}
            </Text>
          </View>

          <View style={{ flexGrow: 1, flexDirection: "row" }}>
            <Text style={PdfStyles.fieldLabel}>Created By:</Text>
            <Text style={PdfStyles.fieldValue}>
              {`${request.createdBy.firstName} ${request.createdBy.lastName}`}
            </Text>
          </View>
        </View>
        <View style={PdfStyles.row}>
          <Text style={PdfStyles.longFieldLabel}>Assigned To:</Text>
          <Text style={PdfStyles.longFieldValue}>{assignees}</Text>
        </View>
        <View style={PdfStyles.row}>
          <Text style={PdfStyles.longFieldLabel}>Location:</Text>
          <Text style={PdfStyles.longFieldValue}>
            {`${request.location?.name || "N/A"}`}
            {ancestors &&
              ancestors.length > 0 &&
              ` (${ancestors.map((l) => l.name).join(" > ")})`}
            {request.location?.active === false && ` (inactive)`}
          </Text>
        </View>
        <View style={PdfStyles.row}>
          <View style={{ flexGrow: 1, flexDirection: "row" }}>
            <Text style={PdfStyles.fieldLabel}>Category:</Text>
            <Text style={PdfStyles.fieldValue}>
              {request.reportingCategory?.name || ""}
            </Text>
          </View>
          <View
            style={{
              flexGrow: 1,
              flexDirection: "row",
              padding: 0,
              margin: 0,
            }}
          >
            <Text style={PdfStyles.fieldLabel}>Reason:</Text>
            <Text style={PdfStyles.fieldValue}>
              {request.reason?.name || ""}
            </Text>
          </View>
        </View>
        <View style={PdfStyles.row}>
          <View style={{ flexGrow: 1, flexDirection: "row" }}>
            <Text style={PdfStyles.fieldLabel}>Start:</Text>
            <Text style={PdfStyles.fieldValue}>
              {request.scheduling.start
                ? new Date(request.scheduling.start).toLocaleString()
                : ""}
            </Text>
          </View>

          <View style={{ flexGrow: 1, flexDirection: "row" }}>
            <Text style={PdfStyles.fieldLabel}>Due:</Text>
            <Text style={PdfStyles.fieldValue}>
              {request.scheduling.due
                ? new Date(request.scheduling.due).toLocaleString()
                : ""}
            </Text>
          </View>
        </View>
        {singleLineFields.length > 0 &&
          singleLineFields.map((field) => (
            <View style={PdfStyles.row} key={field.id}>
              <Text style={PdfStyles.longFieldLabel}>{field.name}:</Text>
              <Text style={PdfStyles.longFieldValue}>
                {getSingleFieldValue(field)}
              </Text>
            </View>
          ))}
        {multilineFields &&
          multilineFields.map((x) => (
            <View style={{ width: "100%", marginTop: 20 }}>
              <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={PdfStyles.fieldLabel}>{x.name}</Text>
                </View>
              </View>
              <View
                style={{
                  minHeight: 60,
                  border: 1,
                  borderColor: "#ccc",
                  padding: 5,
                }}
              >
                <Text>{request.metadata[x.key]?.toString()}</Text>
              </View>
            </View>
          ))}
      </View>

      <View>
        <View style={PdfStyles.fields}>
          {laborsData && laborsData.length > 0 && (
            <View style={{ marginTop: 20 }}>
              <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={PdfStyles.fieldLabel}>Labor</Text>
                </View>
              </View>
              <View style={PdfStyles.table}>
                <View style={PdfStyles.tableRow}>
                  <View
                    style={{
                      ...PdfStyles.tableCol,
                      width: "40%",
                    }}
                  >
                    <Text style={PdfStyles.tableTitle}>Who</Text>
                  </View>
                  <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                    <Text style={PdfStyles.tableTitle}>Type</Text>
                  </View>
                  <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                    <Text style={PdfStyles.tableTitle}>Time</Text>
                  </View>
                  <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                    <Text style={PdfStyles.tableTitle}>Date</Text>
                  </View>
                </View>
                {laborsData.map((labor, index) => (
                  <View style={PdfStyles.tableRow} key={index}>
                    <View style={{ ...PdfStyles.tableCol, width: "40%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {`${labor.laborer.firstName} ${labor.laborer.lastName}`}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {capitalizeFirstLetter(labor.laborType)}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {getFormatedHour(labor.hours)}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {formatDate(labor.datePerformed)}
                      </Text>
                    </View>
                  </View>
                ))}
                <View style={PdfStyles.tableRow}>
                  <View style={{ ...PdfStyles.tableCol, width: "40%" }}>
                    <Text style={PdfStyles.tableCell}>Total Labor Hours:</Text>
                  </View>
                  <View
                    style={{
                      ...PdfStyles.tableCol,
                      width: "60%",
                      textAlign: "right",
                      padding: "5px",
                    }}
                  >
                    <Text style={PdfStyles.tableRow}>{totalLaborHours}</Text>
                  </View>
                </View>
                <View style={PdfStyles.tableRow}>
                  <View style={{ ...PdfStyles.tableCol, width: "40%" }}>
                    <Text style={PdfStyles.tableCell}>Total Labor Cost:</Text>
                  </View>
                  <View
                    style={{
                      ...PdfStyles.tableCol,
                      width: "60%",
                      textAlign: "right",
                      padding: "5px",
                    }}
                  >
                    <Text style={PdfStyles.tableRow}>
                      {formatCurrency(totalLaborCost)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {purchasesData && purchasesData.length > 0 && (
            <View style={{ marginTop: 20 }}>
              <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={PdfStyles.fieldLabel}>Expenses</Text>
                </View>
              </View>
              <View style={PdfStyles.table}>
                <View style={PdfStyles.tableRow}>
                  <View
                    style={{
                      ...PdfStyles.tableCol,
                      width: "20%",
                    }}
                  >
                    <Text style={PdfStyles.tableTitle}>Where</Text>
                  </View>
                  <View
                    style={{
                      ...PdfStyles.tableCol,
                      width: "20%",
                    }}
                  >
                    <Text style={PdfStyles.tableTitle}>What</Text>
                  </View>
                  <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                    <Text style={PdfStyles.tableTitle}>Total Cost</Text>
                  </View>
                  <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                    <Text style={PdfStyles.tableTitle}>Type</Text>
                  </View>
                  <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                    <Text style={PdfStyles.tableTitle}>Date</Text>
                  </View>
                </View>
                {purchasesData.map((purchase, index) => (
                  <View style={PdfStyles.tableRow} key={index}>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {purchase.vendor?.name || ""}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {purchase.description}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {purchase.total.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "USD",
                        })}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {capitalizeFirstLetter(purchase.purchaseType)}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {formatDate(purchase.datePerformed)}
                      </Text>
                    </View>
                  </View>
                ))}
                <View style={PdfStyles.tableRow}>
                  <View style={{ ...PdfStyles.tableCol, width: "40%" }}>
                    <Text style={PdfStyles.tableCell}>
                      Total Expenses Cost:
                    </Text>
                  </View>
                  <View
                    style={{
                      ...PdfStyles.tableCol,
                      width: "60%",
                      textAlign: "right",
                      padding: "5px",
                    }}
                  >
                    <Text style={PdfStyles.tableRow}>
                      {formatCurrency(totalExpensesCost)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {hasInventory && issuanceData && issuanceData.length > 0 && (
            <View style={{ marginTop: 20 }}>
              <View style={{ flexDirection: "column" }}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={PdfStyles.fieldLabel}>Inventory Issuance</Text>
                </View>
              </View>
              <View style={PdfStyles.table}>
                <View style={PdfStyles.tableRow}>
                  <View
                    style={{
                      ...PdfStyles.tableCol,
                      width: "20%",
                    }}
                  >
                    <Text style={PdfStyles.tableTitle}>Item #</Text>
                  </View>
                  <View
                    style={{
                      ...PdfStyles.tableCol,
                      width: "20%",
                    }}
                  >
                    <Text style={PdfStyles.tableTitle}>Description</Text>
                  </View>
                  <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                    <Text style={PdfStyles.tableTitle}>Quantity</Text>
                  </View>
                  <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                    <Text style={PdfStyles.tableTitle}>Total Cost</Text>
                  </View>
                  <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                    <Text style={PdfStyles.tableTitle}>Date</Text>
                  </View>
                </View>
                {issuanceData.map((issuance, index) => (
                  <View style={PdfStyles.tableRow} key={index}>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {
                          issuance.inventoryItemAdjustment?.item?.identifiers
                            .externalId
                        }
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {issuance.inventoryItemAdjustment?.item?.summary || ""}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {issuance.inventoryItemAdjustment?.quantity || ""}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {formatCurrency(issuance.quantity * issuance.unitCost)}
                      </Text>
                    </View>
                    <View style={{ ...PdfStyles.tableCol, width: "20%" }}>
                      <Text style={PdfStyles.tableCell}>
                        {formatDate(issuance.inventoryItemAdjustment?.date)}
                      </Text>
                    </View>
                  </View>
                ))}
                <View style={PdfStyles.tableRow}>
                  <View style={{ ...PdfStyles.tableCol, width: "40%" }}>
                    <Text style={PdfStyles.tableCell}>
                      Total Inventory Cost:
                    </Text>
                  </View>
                  <View
                    style={{
                      ...PdfStyles.tableCol,
                      width: "60%",
                      textAlign: "right",
                      padding: "5px",
                    }}
                  >
                    <Text style={PdfStyles.tableRow}>
                      {formatCurrency(totalInventoryCost)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>
        <View style={PdfStyles.row}>
          <View style={{ flexGrow: 1, flexDirection: "row" }}>
            <Text style={PdfStyles.fieldLabel}>Total Cost:</Text>
            <Text style={PdfStyles.fieldValue}>
              {formatCurrency(
                totalLaborCost + totalExpensesCost + totalInventoryCost
              )}
            </Text>
          </View>
        </View>
      </View>
      <View style={{ ...PdfStyles.footer, bottom: 5 }} fixed>
        <Text>{new Date().toLocaleString()}</Text>
        <Text>HeroHQ by OperationsHero</Text>
        <Text
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
        />
      </View>
    </Page>
  );
};
