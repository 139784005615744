import { RequestSettingsKeys } from "@operations-hero/lib-api-client";
import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState } from "../../../store";
import { AccountWrapper } from "./AccountRoutesWrapper";

const AccountProfile = lazy(() => import("../account-profile/AccountProfile"));
const LocationList = lazy(() => import("../location-list/LocationList"));
const LocationDetails = lazy(() => import("../location-form/LocationDetails"));
const AssetList = lazy(() => import("../asset-list/AssetList"));
const AssetForm = lazy(() => import("../asset-form/AssetForm"));
const AccountUsers = lazy(() => import("../account-users/AccountUsers"));
const UserGroupList = lazy(() => import("../user-group-list/user-group-list"));
const UserGroupForm = lazy(() => import("../user-group-form/user-group-form"));
const BudgetSection = lazy(() => import("../budgets/BudgetsSection"));
const Vendors = lazy(() => import("../vendors/Vendors"));
const LocationsGallery = lazy(
  () => import("../../locations-gallery/LocationsGallery")
);
const LocationsGalleryForm = lazy(
  () => import("../../locations-gallery/Form/LocationsGalleryForm")
);
const AnnouncementForm = lazy(
  () => import("../system-announcement/AnnouncementForm")
);

export const useAdminRoutes = () => {
  const { isProductAdmin, isEventAdmin, isInventoryAdmin } =
    useAuthentication();

  const { accountSettings } = useSelector(
    (state: RootState) => state.localCache
  );

  if (!isProductAdmin && !isEventAdmin && !isInventoryAdmin) {
    // App routes for technicians
    if (
      Boolean(
        accountSettings[RequestSettingsKeys.ALLOW_TECHNICIANS_TO_MANAGE_ASSETS]
      )
    ) {
      return {
        routes: [
          <Route
            path="assets"
            key="routes::account/assets"
            element={<AccountWrapper Element={AssetList} />}
          />,
          <Route
            path="assets/:assetId"
            key="routes::account/assets/assetId"
            element={<AccountWrapper Element={AssetForm} />}
          />,
        ],
      };
    }

    return { routes: [] };
  }

  const routes: React.ReactNode[] = [
    <Route
      path="profile"
      key="routes::account/profile"
      element={<AccountWrapper Element={AccountProfile} />}
    />,
    <Route
      path="locations"
      key="routes::account/locations"
      element={<AccountWrapper Element={LocationList} />}
    />,
    <Route
      path="locations/:locationId"
      key="routes::account/locations/locationId"
      element={<AccountWrapper Element={LocationDetails} />}
    />,
    <Route
      path="assets"
      key="routes::account/assets"
      element={<AccountWrapper Element={AssetList} />}
    />,
    <Route
      path="assets/:assetId"
      key="routes::account/assets/assetId"
      element={<AccountWrapper Element={AssetForm} />}
    />,
    <Route
      path="announcement"
      key="routes::account/announcement"
      element={<AccountWrapper Element={AnnouncementForm} />}
    />,
    <Route
      path="users"
      key="routes::account/users"
      element={<AccountWrapper Element={AccountUsers} />}
    />,
    <Route
      path="user-groups"
      key="routes::account/user-groups"
      element={<AccountWrapper Element={UserGroupList} />}
    />,
    <Route
      path="user-groups/:groupId"
      key="routes::account/user-groups/groupId"
      element={<AccountWrapper Element={UserGroupForm} />}
    />,
    <Route
      path="budgets"
      key="routes::account/budgets"
      element={<AccountWrapper Element={BudgetSection} />}
    />,
    <Route
      path="vendors"
      key="routes::account/vendors"
      element={<AccountWrapper Element={Vendors} />}
    />,
    <Route
      path="locations-gallery"
      key="routes::account/locations-gallery"
      element={<AccountWrapper Element={LocationsGallery} />}
    />,
    <Route
      path="locations-gallery/:id"
      key="routes::account/locations-gallery/gallery-item"
      element={<AccountWrapper Element={LocationsGalleryForm} />}
    />,
  ];

  return { routes };
};
