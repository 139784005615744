import { Badge } from "@chakra-ui/react";
import { ApiAssetCondition } from "@operations-hero/lib-api-client";

export const colorSchemeMap = {
  [ApiAssetCondition.failing]: "red",
  [ApiAssetCondition.poor]: "orange",
  [ApiAssetCondition.fair]: "blue",
  [ApiAssetCondition.good]: "yellow",
  [ApiAssetCondition.excelent]: "green",
};

export interface AssetConditionBadgeProps {
  value: ApiAssetCondition;
}

export const AssetConditionBadge = ({ value }: AssetConditionBadgeProps) => {
  return (
    <Badge
      variant="outline"
      fontSize="small"
      colorScheme={colorSchemeMap[value]}
      rounded="lg"
      fontWeight="semibold"
      px="2"
    >
      {value}
    </Badge>
  );
};
