import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Collapse,
  Container,
  Divider,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Link,
  Spacer,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  ApiComment,
  ApiLaborTransaction,
  ApiLocationSummary,
  ApiProject,
  ApiRequest,
  ApiRequestAssignee,
  ApiRequestPriority,
  ApiRequestStatus,
  ApiRequestType,
  ApiTransaction,
  ApiTransactionType,
  ApiUserSummary,
  ApiWorkflowField,
  ApiWorkflowFieldDataType,
  ApiWorkflowReasonSummary,
  ApiWorkflowReportingCategorySummary,
  OneClickRequestMetadata,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import { unwrapResult } from "@reduxjs/toolkit";
import { MultiValue } from "chakra-react-select";
import { formatDistanceToNow } from "date-fns";
import { EditorState } from "draft-js";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { HiOutlineSparkles } from "react-icons/hi2";
import { ImWarning } from "react-icons/im";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Attachment,
  Attachments,
} from "../../components/attachments/Attachments";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { DateBadge } from "../../components/badges/DateBadge";
import { RequestKeyBadge } from "../../components/badges/RequestKeyBadge";
import { StatusBadge } from "../../components/badges/StatusBadge";
import { CustomFieldInput } from "../../components/custom-field-input/CustomFieldInput";
import { RichTextEditorComments } from "../../components/form-helpers/rich-text-editor/RichTextEditorComments";
import { StyledDatePicker } from "../../components/inputs/StyledDatePicker";
import { Pager } from "../../components/pager/Pager";
import { AssigneeAutocomplete } from "../../components/selects/AssigneeAutocomplete";
import { CommonStringSelect } from "../../components/selects/CommonStringSelect";
import { LocationAutocomplete } from "../../components/selects/LocationAutocomplete";
import { ProjectAutocomplete } from "../../components/selects/ProjectAutocomplete";
import { ReasonAutocomplete } from "../../components/selects/ReasonAutocomplete";
import { ReportingCategoryAutocomplete } from "../../components/selects/ReportingCategoryAutocomplete";
import { RequesterAutocomplete } from "../../components/selects/RequesterAutocomplete";
import { RequestPrioritySelect } from "../../components/selects/RequestPrioritySelect";
import { RequestTypeSelect } from "../../components/selects/RequestTypeSelect";
import { useProductSubscriptions } from "../../components/shell/AppShell";
import { useShowToast } from "../../hooks/showToast";
import { useConvertMentions } from "../../hooks/useConvertMentions";
import { RootState, useThunkDispatch } from "../../store";
import {
  cancelChange,
  setGalleryImagesPage,
  setIsOpenLocationGallery,
  setProject,
  setVisibleSchemaFields,
  unloadForm,
  updateTaskBooksCompleted,
} from "../../store/request-form/request-form.slice";
import { createTransaction } from "../../store/request-form/thunks/createTransaction.thunk";
import { initRequestForm } from "../../store/request-form/thunks/initRequestForm.thunk";
import {
  loadGalleryImages,
  loadLocationsGallery,
} from "../../store/request-form/thunks/loadLocationGallery.thunk";
import { removeAttachment } from "../../store/request-form/thunks/removeAttachment.thunk";
import { saveComment } from "../../store/request-form/thunks/saveComment.thunk";
import { updateRequest } from "../../store/request-form/thunks/updateRequest.thunk";
import { uploadAttachment } from "../../store/request-form/thunks/uploadAttachment.thunk";
import { setCurrentPage } from "../../store/request-list.slice";
import { getVisibleFields } from "../../utils/getVisibleFields";
import {
  findLocationChildrenFunction,
  useLocationUtils,
} from "../../utils/locationUtils";
import { scrollToSection } from "../../utils/scrollToSection";
import {
  getHourAndMinutesNotation,
  getMoneyNotation,
} from "../../utils/textNotations";
import { transformCommentToPrint } from "../../utils/transformComment";
import { Products } from "../account-settings/location-list/LocationList";
import { EventBasicDetails } from "../events/events-list/EventBasicInfo";
import { GalleryImageCard } from "../locations-gallery/GalleryCard";
import { AssetSection } from "./assets/AssetSection";
import { AssociationRequestsModal } from "./associations/AssociationRequestsModal";
import { RequestAssociationList } from "./associations/RequestAssociationsList";
import { ChangeModal } from "./ChangeModal";
import { convertToSave } from "./comments/Comment";
import { CommentsSection } from "./comments/CommentsSection";
import { FormSection } from "./FormSection";
import { HoursCostSection } from "./HoursCostSection";
import { InventoryIssuance } from "./inventorySection/InventoryIssuance";
import { InventoryRequests } from "./inventorySection/InventoryRequest";
import { ActionMenuType, MoveRequestModal } from "./MoveRequestModal";
import { RequestNotification } from "./notification/RequestNotification";
import { PrintReportModal } from "./print/PrintReportModal";
import { PrintRequestModal } from "./print/PrintRequestModal";
import { RequestFormSkeleton } from "./RequestFormSkeleton";
import { ScheduledRequestSection } from "./ScheduledRequestSection";
import { ScrollToSectionButton, Section } from "./ScrollToSectionButton";
import { StatusChangeButtons } from "./StatusChangeButtons";
import { TaskbooksSection } from "./taskbooks/TaskbooksSection";
import { TimeLine } from "./timeline/Timeline";
import { EstimateLabors } from "./transactions/labors/EstimateLabors";
import { LaborsSection } from "./transactions/labors/LaborsSection";
import { TransactionLaborerWithGroups } from "./transactions/labors/LaborTransactionForm";
import { CostSection } from "./transactions/purchases/CostSection";
import { EstimatedExpenses } from "./transactions/purchases/EstimateExpenses";
import { PurchasesSection } from "./transactions/purchases/PurchasesSection";

const CompleteRequestTimeline = lazy(
  () => import("./timeline/CompleteRequestTimeline")
);

type RequestLocationState = {
  currentPage?: number;
};

export interface RequestFormRouteParams {
  key: string;
}

export const DetailField = ({
  label,
  children,
  ...rest
}: {
  label: string;
} & FlexProps) => {
  return (
    <Flex {...rest}>
      <Box w="100px">{label}</Box>
      <Box flex="1">{children}</Box>
    </Flex>
  );
};

export const RequestForm = () => {
  const { key } = useParams();
  const {
    currentUser,
    currentAccount,
    apiClient,
    isProductAdmin,
    isInventoryStandard,
    isInventoryAdmin,
  } = useAuthentication();
  const { hasInventory } = useProductSubscriptions();

  /* redux  */
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { getUserPolicyLocationsWithChildrens } = useLocationUtils();

  const location = useLocation();

  const currentPage = useMemo(() => {
    const locationState = location.state as RequestLocationState;
    return locationState?.currentPage;
  }, [location.state]);

  /* chakra */
  const toast = useToast();

  const { taskbooks, comments, isInit } = useSelector(
    (state: RootState) => state.requestForm
  );

  const {
    isRequesterOnly,
    isApproverOnly,
    formIsLocked,
    request,
    workflow,
    policy,
    schemaFields,
    transition,
    changeModal,
    attachments,
    canReopen,
    transactions,
    isContractorOnly,
    isContractor,
    taskBooksCompleted,
    isOneClickAction,
    locationGallery,
    project,
  } = useSelector((state: RootState) => state.requestForm);

  const {
    locationMap,
    categoriesMap,
    reasonsMap,
    descendantsMap,
    isAdmin,
    isReviewer,
    isCatalogIssuer,
    policyMap,
  } = useSelector((state: RootState) => state.localCache);

  const { laborsData } = useSelector(
    (state: RootState) => state.requestForm.transactions.labors
  );

  const { issuanceData } = useSelector(
    (state: RootState) => state.requestForm.transactions.issuance
  );

  const showHoursAndCost = useMemo(() => {
    const isWorkflowAdmin = workflow && policyMap[workflow.id].admin;
    return isReviewer || isWorkflowAdmin;
  }, [workflow, policyMap, isReviewer]);

  const totalCost = useMemo(() => {
    let inventoryTotalCost: number = 0;
    let laborsTotalCost: number = 0;

    laborsData.forEach((labor: ApiTransaction) => {
      laborsTotalCost += labor.total;
    });

    issuanceData.forEach((issuance) => {
      if (issuance.type === ApiTransactionType.issuance)
        inventoryTotalCost += issuance.quantity * issuance.unitCost;
    });

    return {
      expenses: parseFloat(transactions.totalPurchases.toString()),
      inventoryTotalCost,
      laborsTotalCost,
    };
  }, [issuanceData, laborsData, transactions.totalPurchases]);

  const allowedLocations = useMemo(() => {
    if (!policy || !workflow) return [];

    const { admin } = policy;

    if (isProductAdmin || admin || workflow.allowUsersToSubmitRequests) {
      return [];
    }

    const restrictedLocations = getUserPolicyLocationsWithChildrens(policy);

    return restrictedLocations.map((loc) => loc.id);
  }, [isProductAdmin, policy, workflow, getUserPolicyLocationsWithChildrens]);

  const [engine, setEngine] = useState<SchemaRulesEngine>();
  const [menuActionValue, setMenuActionValue] = useState<ActionMenuType | null>(
    null
  );
  const [visibleFields, setVisibleFields] = useState<ApiWorkflowField[]>([]);
  const [commentsToPrint, setCommentsToPrint] = useState<ApiComment[]>();
  const [isSummaryInvalid, setInvalidSummary] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAssociation,
    onOpen: onOpenAssociation,
    onClose: onCloseAssociation,
  } = useDisclosure();

  const {
    isOpen: printIsOpen,
    onOpen: printOnOpen,
    onClose: printOnClose,
  } = useDisclosure();

  const {
    isOpen: printReportIsOpen,
    onOpen: printReportOnOpen,
    onClose: printReportOnClose,
  } = useDisclosure();

  const navigate = useNavigate();
  const initOnce = useRef(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const overdueWarningColor = useColorModeValue("red.800", "red.200");
  const errorColor = useColorModeValue("red.500", "red.300");
  const statusBadgeColor = useColorModeValue("gray.100", "none");
  const showToast = useShowToast();

  const [isChangeModalSaving, setIsChangeModalSaving] = useState(false);

  const { editorState: summary } = useConvertMentions({
    value: request?.summary,
    mentioned: request?.mentioned,
  });

  const {
    showCategory,
    showReason,
    showComments,
    showStartDate,
    showDueDate,
    showBudgetOnRequests,
    showExpenses,
    showEstimatedCost,
    showLabor,
    showEstimatedLabor,
    showTaskbooks,
    showAssignTo,
    showProject,
  } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const showCompletedIndicator = useMemo(() => {
    return (
      request &&
      [ApiRequestStatus.review, ApiRequestStatus.closed].includes(
        request.status
      ) &&
      request.scheduling.completed
    );
  }, [request]);

  const isOverdue = useMemo(() => {
    const now = new Date();
    return (
      request &&
      ![
        ApiRequestStatus.canceled,
        ApiRequestStatus.closed,
        ApiRequestStatus.review,
      ].includes(request.status) &&
      request.scheduling.due != null &&
      new Date(request.scheduling.due).getTime() < now.getTime()
    );
  }, [request]);

  const canOpenActionsMenu = useMemo(() => {
    if (
      isProductAdmin ||
      policy?.admin ||
      policy?.reviewer ||
      policy?.technician ||
      policy?.contractor
    ) {
      return true;
    }
    return false;
  }, [
    isProductAdmin,
    policy?.admin,
    policy?.reviewer,
    policy?.technician,
    policy?.contractor,
  ]);

  const canMoveRequest = useMemo(() => {
    if (isProductAdmin || policy?.admin || policy?.reviewer) {
      return true;
    }
    return false;
  }, [isProductAdmin, policy?.admin, policy?.reviewer]);

  const actionOptions = useMemo(() => {
    if (!canOpenActionsMenu) {
      return [];
    }

    const actions: string[] = [];

    if (canMoveRequest) {
      actions.push("associate");
      actions.push("clone");
      actions.push("move");
    }

    if (!isMobile) {
      actions.push("print");
    }

    return actions;
  }, [canOpenActionsMenu, canMoveRequest, isMobile]);

  const showAssignToMe = useMemo(() => {
    return (
      (policy?.technician === true ||
        (Array.isArray(policy?.technician) &&
          request?.location &&
          policy?.technician !== undefined &&
          [
            //@ts-ignore
            ...findLocationChildrenFunction(
              descendantsMap,
              policy?.technician,
              new Set<string>()
            ),
          ].includes(request.location.id))) &&
      request?.assignees.every((a) => a.assignee.id !== currentUser.id)
    );
  }, [
    policy?.technician,
    request?.location,
    request?.assignees,
    descendantsMap,
    currentUser.id,
  ]);

  const canSeeCostSection = useMemo(() => {
    if (!workflow) return false;
    return showBudgetOnRequests || request?.budget;
  }, [workflow, request, showBudgetOnRequests]);

  const isTechPlus = useMemo(
    () =>
      isProductAdmin || policy?.admin || policy?.reviewer || policy?.technician
        ? true
        : false,
    [isProductAdmin, policy]
  );

  const canViewInventoryRequest = useMemo(
    () => hasInventory && isTechPlus,
    [hasInventory, isTechPlus]
  );

  const canCreateInventoryRequest = useMemo(
    () =>
      hasInventory && (isInventoryStandard || isInventoryAdmin) && isTechPlus,
    [hasInventory, isInventoryAdmin, isInventoryStandard, isTechPlus]
  );

  const canViewInventoryIssuance = useMemo(
    () => hasInventory && isTechPlus,
    [hasInventory, isTechPlus]
  );

  const canCreateInventoryIssuance = useMemo(
    () => hasInventory && (isInventoryAdmin || isCatalogIssuer) && isTechPlus,
    [hasInventory, isInventoryAdmin, isTechPlus, isCatalogIssuer]
  );

  const orderedVisibleFields = useMemo(() => {
    const fields = visibleFields.filter(
      (field) => field.dataType !== ApiWorkflowFieldDataType.system
    );

    const res = schemaFields.filter((sf) => {
      if (fields.some((fi) => fi.id === sf.field.id)) {
        return true;
      }
      return false;
    });
    return res.sort((a, b) => {
      if (!a.position) return 1;
      if (!b.position) return -1;
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });
  }, [schemaFields, visibleFields]);

  const handleDeleteAttachment = useCallback(
    (attachment: Attachment) => {
      if (!request) return;
      thunkDispatch(
        removeAttachment({
          apiClient,
          account: currentAccount,
          idOrKey: request.key,
          attachmentId: attachment.uploadId!,
        })
      );
    },
    [apiClient, currentAccount, request, thunkDispatch]
  );

  const handleNewAttachments = useCallback(
    (attachments: Attachment[]) => {
      if (!request) return;
      attachments.forEach((attachment) => {
        thunkDispatch(
          uploadAttachment({
            apiClient,
            account: currentAccount,
            idOrKey: request.key,
            attachment,
          })
        );
      });
    },
    [apiClient, currentAccount, request, thunkDispatch]
  );

  const handleChangeTaskBooksCompleted = useCallback(
    (value: boolean) => {
      dispatch(updateTaskBooksCompleted(value));
    },
    [dispatch]
  );

  const handlePropertyChange = useCallback(
    (delta: Partial<ApiRequest>, isChangeModal = false) => {
      // Allow reopen to flow through
      if (!key) return;
      if (formIsLocked && delta.status !== ApiRequestStatus.queued) {
        toast({
          description: "Request is closed",
          duration: 1500,
          id: "update-wo-locked",
          isClosable: true,
          position: "top",
          status: "error",
          title: "Request is currently locked",
        });
        return;
      }

      return thunkDispatch(
        updateRequest({
          account: currentAccount,
          apiClient: apiClient,
          idOrKey: key,
          request: request as ApiRequest,
          delta,
          engine: engine!,
          isChangeModal,
          isProductAdmin,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Request Saved");
        })
        .catch((err) => {
          if (err?.changeVerification || err?.delta) {
            if (
              err.changeVerification.delta.status === "review" ||
              err.changeVerification.delta.status === "closed"
            ) {
              if (
                workflow?.requireTaskbooksCompletion &&
                taskbooks.find((taskBook) => taskBook.completed === null)
              ) {
                handleChangeTaskBooksCompleted(false);
                showToast(
                  "error",
                  "Please complete all taskbooks before completing the request.",
                  undefined,
                  err.status ? `Status Code: ${err.status}` : undefined
                );
                return err;
              }
              handleChangeTaskBooksCompleted(true);
              return err;
            }
            handleChangeTaskBooksCompleted(true);
            return err;
          }
          handleChangeTaskBooksCompleted(true);
          showToast("error", "Error Saving Request");
        });
    },
    [
      formIsLocked,
      thunkDispatch,
      currentAccount,
      apiClient,
      key,
      request,
      engine,
      isProductAdmin,
      toast,
      handleChangeTaskBooksCompleted,
      workflow?.requireTaskbooksCompletion,
      taskbooks,
      showToast,
    ]
  );

  const handleRequesterChange = useCallback(
    (requester: ApiUserSummary | null) => {
      if (!requester) return;
      handlePropertyChange({ requester });
    },
    [handlePropertyChange]
  );

  const handleAssigneeChange = useCallback(
    (assignees: MultiValue<ApiRequestAssignee>) => {
      const cleaned =
        assignees === null
          ? []
          : Array.isArray(assignees)
            ? assignees
            : [assignees];
      handlePropertyChange({
        assignees: cleaned,
      });
    },
    [handlePropertyChange]
  );

  const handleAssignToMe = useCallback(() => {
    if (
      !request?.assignees.find(
        (assign) => assign.assignee.id === currentUser.id
      )
    ) {
      const userToAssign: ApiRequestAssignee = {
        type: "user",
        assignee: currentUser,
      };
      const user = [userToAssign];
      handlePropertyChange({
        assignees: user,
      });
    }
  }, [currentUser, handlePropertyChange, request?.assignees]);

  const handleOnGalleryPageChange = useCallback(
    (value: number) => {
      dispatch(setGalleryImagesPage(value));

      if (locationGallery.gallery) {
        thunkDispatch(
          loadGalleryImages({
            apiClient,
            accountId: currentAccount.id,
            galleryId: locationGallery.gallery.id,
          })
        );
      }
    },
    [
      apiClient,
      currentAccount.id,
      dispatch,
      locationGallery.gallery,
      thunkDispatch,
    ]
  );

  const handleChangeLocationGalleryData = useCallback(
    async (location: ApiLocationSummary | null) => {
      if (location !== null) {
        await thunkDispatch(
          loadLocationsGallery({
            apiClient,
            accountId: currentAccount.id,
            locationId: location.id,
          })
        );
      }
    },
    [apiClient, currentAccount.id, thunkDispatch]
  );

  const handleLocationChange = useCallback(
    (location: ApiLocationSummary | null) => {
      handlePropertyChange({ location });
      handleChangeLocationGalleryData(location);
    },
    [handlePropertyChange, handleChangeLocationGalleryData]
  );

  const handlePriortyChange = useCallback(
    (priority: ApiRequestPriority) => {
      handlePropertyChange({ priority });
    },
    [handlePropertyChange]
  );

  const handleTypeChange = useCallback(
    (type: ApiRequestType) => {
      handlePropertyChange({ type });
    },
    [handlePropertyChange]
  );

  const handleStatusChange = useCallback(
    (status: ApiRequestStatus) => {
      handlePropertyChange({ status });
    },
    [handlePropertyChange]
  );

  const handleSummaryBlur = useCallback(
    (summary: EditorState) => {
      const response = convertToSave(summary);
      if (response && response.trim() === request?.summary.trim()) return;
      const isEmpty = !summary.getCurrentContent().hasText();
      setInvalidSummary(isEmpty);
      handlePropertyChange({ summary: response });
    },
    [handlePropertyChange, request]
  );

  const handleCustomFieldChange = useCallback(
    (field: ApiWorkflowField) => {
      return (newValue: any) => {
        const metadata = {
          ...(request?.metadata || {}),
          [field.key]: newValue,
        };
        handlePropertyChange({ metadata });
      };
    },
    [handlePropertyChange, request]
  );

  const handleStartDateChange = useCallback(
    (value: Date | null) => {
      const dateString = !value ? null : value.toISOString();
      handlePropertyChange({
        scheduling: { ...request!.scheduling, start: dateString },
      });
    },
    [handlePropertyChange, request]
  );

  const handleDueDateChange = useCallback(
    (value: Date | null) => {
      const dateString = !value ? null : value.toISOString();
      handlePropertyChange({
        scheduling: { ...request!.scheduling, due: dateString },
      });
    },
    [handlePropertyChange, request]
  );

  const handleCategoryChange = useCallback(
    (reportingCategory: ApiWorkflowReportingCategorySummary | null) => {
      handlePropertyChange({ reportingCategory });
    },
    [handlePropertyChange]
  );

  const handleReasonChange = useCallback(
    (reason: ApiWorkflowReasonSummary | null) => {
      handlePropertyChange({ reason });
    },
    [handlePropertyChange]
  );

  const handleProjectChange = useCallback(
    (value: ApiProject | null) => {
      handlePropertyChange({ projectId: value?.id ?? null })?.then(
        (changeModalHasOpen) => {
          if (changeModalHasOpen) return;
          else {
            dispatch(setProject(value));
          }
        }
      );
    },
    [handlePropertyChange, dispatch]
  );

  const handleOnSaveLaborTransaction = useCallback(
    async (laborTransaction?: TransactionLaborerWithGroups) => {
      if (!laborTransaction) return;
      if (!laborTransaction.laborer.isGroup) {
        thunkDispatch(
          createTransaction({
            apiClient,
            account: currentAccount,
            transaction: laborTransaction,
            request,
          })
        )
          .then(unwrapResult)
          .catch(() => {
            showToast("error", "Error Saving Labor Transaction");
          });
      }

      if (laborTransaction.laborer.isGroup) {
        let groupMembers;
        if (laborTransaction.laborer.groupMembers) {
          groupMembers = laborTransaction.laborer.groupMembers;
        } else {
          const findGroupMembers = await apiClient.findUserGroupMembers(
            currentAccount.id,
            laborTransaction.laborer.id
          );
          groupMembers = findGroupMembers.data.map((gm) => gm.user);
        }
        Promise.all(
          groupMembers.map((member) => {
            return thunkDispatch(
              createTransaction({
                apiClient,
                account: currentAccount,
                transaction: { ...laborTransaction, laborer: member },
                request,
              })
            );
          })
        )
          .then(() => {
            showToast("success", "Labor transactions created successfully");
          })
          .catch((error) => {
            showToast("error", "Error Saving Labor Transactions");
          });
      }
    },
    [apiClient, currentAccount, request, showToast, thunkDispatch]
  );

  const handleChangeModalSave = useCallback(
    ({
      delta,
      comment,
      laborTransaction,
      project,
    }: {
      delta: Partial<ApiRequest>;
      comment?: ApiComment;
      laborTransaction?: ApiLaborTransaction;
      project?: ApiProject | null;
    }) => {
      if (!request) {
        return;
      }

      setIsChangeModalSaving(true);

      handlePropertyChange(delta, true)?.finally(() => {
        setIsChangeModalSaving(false);
      });

      if (project !== undefined) {
        dispatch(setProject(project));
      }

      if (comment) {
        thunkDispatch(
          saveComment({ apiClient, account: currentAccount, comment, request })
        )
          .then(unwrapResult)
          .catch((e) => {
            toast({
              duration: 1500,
              id: "update-wo-err-comment",
              isClosable: true,
              position: "top",
              status: "error",
              title: "Error Saving Comment",
            });
          });
      }

      handleOnSaveLaborTransaction(laborTransaction);
    },
    [
      request,
      handlePropertyChange,
      handleOnSaveLaborTransaction,
      thunkDispatch,
      apiClient,
      currentAccount,
      toast,
      dispatch,
    ]
  );

  const handleChangeModalCancel = useCallback(() => {
    dispatch(cancelChange());
  }, [dispatch]);

  const onChangeActionMenu = useCallback(
    (value: ActionMenuType) => {
      setMenuActionValue(value);

      if (value === "move" || value === "clone") {
        onOpen();
        return;
      }
      if (value === "print") {
        printOnOpen();
        return;
      }
      if (value === "associate") {
        onOpenAssociation();
        return;
      }
    },
    [onOpen, onOpenAssociation, printOnOpen]
  );

  useEffect(() => {
    setCommentsToPrint(transformCommentToPrint(comments));
  }, [setCommentsToPrint, comments]);

  useEffect(() => {
    thunkDispatch(
      initRequestForm({
        apiClient,
        account: currentAccount,
        key: key || "",
        user: currentUser,
        isProductAdmin,
        hasInventory,
      })
    );

    return () => {
      dispatch(unloadForm());
    };
  }, [
    key,
    apiClient,
    currentAccount,
    currentUser,
    isProductAdmin,
    hasInventory,
    dispatch,
    thunkDispatch,
  ]);

  // this updates the working state once the
  // request is loaded or saved (changed in redux)
  useEffect(() => {
    if (!request || !workflow || !policy) {
      return;
    }
    const rulesEngine =
      engine ||
      new SchemaRulesEngine({
        account: currentAccount,
        user: currentUser,
        form: "full",
        schemaFields: schemaFields,
        workflow: workflow,
        policy: policy,
        isProductAdmin,
      });

    if (!engine) {
      setEngine(rulesEngine);
    }
    const fields = rulesEngine.getVisibleFields({
      request,
      includeDeleted: false,
    });

    setVisibleFields(fields);
    dispatch(setVisibleSchemaFields(fields));
  }, [
    currentAccount,
    currentUser,
    engine,
    isProductAdmin,
    policy,
    request,
    schemaFields,
    workflow,
    dispatch,
  ]);

  useEffect(() => {
    if (!request || !isOneClickAction || !engine) return;
    const search = location.search;
    const urlQueryParams = new URLSearchParams(search);
    const metadata = urlQueryParams.get("meta");
    if (!metadata) return;
    const meta: OneClickRequestMetadata = JSON.parse(window.atob(metadata));

    const { requestKey, action } = meta;
    const { status } = request;
    if (status !== ApiRequestStatus.new && action === "approve") {
      showToast(
        "info",
        "This request has already been approved",
        undefined,
        undefined,
        4000
      );
      navigate(`/requests/${requestKey}`, { replace: true });
      return;
    }

    if (status === ApiRequestStatus.canceled && action === "cancel") {
      showToast(
        "info",
        "This request has already been canceled",
        undefined,
        undefined,
        4000
      );
      navigate(`/requests/${request.key}`, { replace: true });
      return;
    }
    if (!initOnce.current) {
      action === "approve" && handleStatusChange(ApiRequestStatus.approved);

      action === "cancel" && handleStatusChange(ApiRequestStatus.canceled);
      initOnce.current = true;
    }
  }, [
    apiClient,
    currentAccount.id,
    dispatch,
    handleStatusChange,
    navigate,
    location.search,
    isOneClickAction,
    request,
    engine,
    showToast,
  ]);

  const [isTaskbooksVisible, setIsTaskbooksVisible] = useState(false);
  const [isAssetsVisible, setIsAssetsVisible] = useState(false);
  const [isLaborVisible, setIsLaborVisible] = useState(false);
  const [isExpensesVisible, setIsExpensesVisible] = useState(false);
  const [isInventoryVisible, setIsInventoryVisible] = useState(false);

  const taskbookSectionRef = useRef(null);
  const assetsSectionRef = useRef(null);
  const laborSectionRef = useRef(null);
  const expensesSectionRef = useRef(null);
  const inventorySectionRef = useRef(null);

  const taskbooksFieldRef = useRef(null);
  const assetsFieldRef = useRef(null);

  const updateTaskbookVisibility = useCallback((isVisible: boolean) => {
    setIsTaskbooksVisible(isVisible);
  }, []);
  const updateAssetsVisibility = useCallback((isVisible: boolean) => {
    setIsAssetsVisible(isVisible);
  }, []);
  const updateLaborVisibility = useCallback((isVisible: boolean) => {
    setIsLaborVisible(isVisible);
  }, []);
  const updateExpensesVisibility = useCallback((isVisible: boolean) => {
    setIsExpensesVisible(isVisible);
  }, []);
  const updateInventoryVisibility = useCallback((isVisible: boolean) => {
    setIsInventoryVisible(isVisible);
  }, []);

  const urlHash = useRef(location.hash);
  const hasScrolled = useRef(false);
  const [isAssetsRendered, setIsAssetRendered] = useState(false);
  const [isTaskbooksRendered, setIsTaskbooksRendered] = useState(false);

  const updateAssetsRenderState = (state: boolean) => {
    setIsAssetRendered(state);
  };

  const updateTaskbooksRenderState = (state: boolean) => {
    setIsTaskbooksRendered(state);
  };

  useEffect(() => {
    if (isInit && !hasScrolled.current) {
      if (urlHash.current === "#assets" && isAssetsRendered) {
        scrollToSection(assetsSectionRef);
        hasScrolled.current = true;
      }
      if (urlHash.current === "#taskbooks" && isTaskbooksRendered) {
        scrollToSection(taskbookSectionRef);
        hasScrolled.current = true;
      }
    }
  }, [isInit, isAssetsRendered, isTaskbooksRendered]);

  useEffect(() => {
    currentPage && dispatch(setCurrentPage(currentPage));
    return () => {
      dispatch(setCurrentPage(1));
    };
  }, [dispatch, currentPage]);

  const handleOnClickLocationsGallery = useCallback(() => {
    const newValue = !locationGallery.isOpen;
    dispatch(setIsOpenLocationGallery(newValue));

    if (
      locationGallery.gallery !== null &&
      locationGallery.loading === "idle"
    ) {
      thunkDispatch(
        loadGalleryImages({
          apiClient,
          accountId: currentAccount.id,
          galleryId: locationGallery.gallery.id,
        })
      );
    }
  }, [
    apiClient,
    currentAccount.id,
    dispatch,
    locationGallery.gallery,
    locationGallery.isOpen,
    locationGallery.loading,
    thunkDispatch,
  ]);

  useEffect(() => {
    window.onpopstate = () => {
      sessionStorage.setItem("from", "request");
    };
  }, []);

  return (
    <Container maxWidth="8xl">
      <Stack>
        <Grid
          templateColumns={["repeat(8, 1fr)"]}
          verticalAlign="center"
          columnGap={2}
          rowGap={4}
          pb={2}
        >
          <GridItem colSpan={[8, 8, 4, 4]}>
            {!isMobile ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap="4"
              >
                <Flex flexDirection="row" alignItems="center">
                  {request ? (
                    <RequestKeyBadge
                      request={request}
                      mr={3}
                      fontSize="2xl"
                      flexDirection="row"
                      alignItems="center"
                    />
                  ) : (
                    <Text pr={3} fontSize="2xl" fontWeight="semibold">
                      {key}
                    </Text>
                  )}
                  {request && showCompletedIndicator ? (
                    <Tag
                      colorScheme="green"
                      verticalAlign="bottom"
                      minW="fit-content"
                      h="max-content"
                    >
                      <TagLeftIcon as={AiOutlineCheckCircle} />
                      <TagLabel>
                        <HStack spacing="1">
                          <Text as="span">Completed</Text>
                          <DateBadge
                            date={request.scheduling.completed}
                            showRelative={true}
                          />
                        </HStack>
                      </TagLabel>
                    </Tag>
                  ) : null}
                  {request && isOverdue ? (
                    <Tag
                      colorScheme="red"
                      verticalAlign="bottom"
                      minW="fit-content"
                      h="max-content"
                    >
                      <TagLeftIcon as={ImWarning} />
                      <TagLabel>
                        <HStack spacing="1">
                          <Text as="span">Overdue</Text>
                          <DateBadge
                            date={request.scheduling.due}
                            showRelative={true}
                          />
                        </HStack>
                      </TagLabel>
                    </Tag>
                  ) : null}
                </Flex>
                {request && (
                  <Flex columnGap="2" flexDir="row">
                    {request && (
                      <Box>
                        <StatusBadge
                          status={request.status}
                          badgeProps={{
                            variant: "outline",
                            bg: statusBadgeColor,
                            px: "2",
                            rounded: "md",
                          }}
                        />
                      </Box>
                    )}
                    <FormControl id="priority" maxWidth="max-content">
                      <RequestPrioritySelect
                        value={request.priority}
                        onChange={handlePriortyChange}
                        isDisabled={
                          isRequesterOnly ||
                          isApproverOnly ||
                          isContractorOnly ||
                          isContractor ||
                          formIsLocked
                        }
                      />
                    </FormControl>
                    <FormControl id="type" maxWidth="max-content">
                      <RequestTypeSelect
                        type={request.type}
                        priority={request.priority}
                        onChange={handleTypeChange}
                        isDisabled={
                          isRequesterOnly ||
                          isContractorOnly ||
                          isContractor ||
                          formIsLocked
                        }
                      />
                    </FormControl>
                    <FormControl id="actions" maxWidth="max-content">
                      {canOpenActionsMenu && (
                        <CommonStringSelect
                          value="actions"
                          options={actionOptions}
                          isDisabled={!canOpenActionsMenu}
                          defaultValue={{ label: "Action", value: "Action" }}
                          onChange={(value) =>
                            onChangeActionMenu(value as ActionMenuType)
                          }
                        />
                      )}
                    </FormControl>
                  </Flex>
                )}
              </Box>
            ) : (
              <Box display="flex" flexDir="column" rowGap="2">
                <Flex flexDir="row" justifyContent="space-between">
                  {request ? (
                    <RequestKeyBadge
                      request={request}
                      mr={3}
                      fontSize="2xl"
                      flexDirection="row"
                      alignItems="center"
                    />
                  ) : (
                    <Text pr={3} fontSize="2xl" fontWeight="semibold">
                      {key}
                    </Text>
                  )}
                  {request && (
                    <Box>
                      <StatusBadge
                        status={request.status}
                        badgeProps={{
                          variant: "outline",
                          bg: statusBadgeColor,
                          px: "2",
                          py: "1",
                          rounded: "md",
                        }}
                      />
                    </Box>
                  )}
                </Flex>
                {request && showCompletedIndicator ? (
                  <Tag
                    colorScheme="green"
                    verticalAlign="bottom"
                    minW="fit-content"
                    maxW="fit-content"
                  >
                    <TagLeftIcon as={AiOutlineCheckCircle} />
                    <TagLabel>
                      <HStack spacing="1">
                        <Text as="span">Completed</Text>
                        <DateBadge
                          date={request.scheduling.completed}
                          showRelative={true}
                        />
                      </HStack>
                    </TagLabel>
                  </Tag>
                ) : null}
                {request && isOverdue ? (
                  <Tag
                    colorScheme="red"
                    verticalAlign="bottom"
                    minW="fit-content"
                    maxW="fit-content"
                  >
                    <TagLeftIcon as={ImWarning} />
                    <TagLabel>
                      <HStack spacing="1">
                        <Text as="span">Overdue</Text>
                        <DateBadge
                          date={request.scheduling.due}
                          showRelative={true}
                        />
                      </HStack>
                    </TagLabel>
                  </Tag>
                ) : null}
                {request && (
                  <Flex columnGap="2" flexWrap="wrap" rowGap="2">
                    <FormControl id="priority" maxWidth="max-content">
                      <RequestPrioritySelect
                        value={request.priority}
                        onChange={handlePriortyChange}
                        isDisabled={
                          isRequesterOnly ||
                          isApproverOnly ||
                          isContractorOnly ||
                          isContractor ||
                          formIsLocked
                        }
                      />
                    </FormControl>
                    <FormControl id="type" maxWidth="max-content">
                      <RequestTypeSelect
                        type={request.type}
                        priority={request.priority}
                        onChange={handleTypeChange}
                        isDisabled={
                          isRequesterOnly ||
                          isContractorOnly ||
                          isContractor ||
                          formIsLocked
                        }
                      />
                    </FormControl>
                    <FormControl id="actions" maxWidth="max-content">
                      {canOpenActionsMenu && (
                        <CommonStringSelect
                          value="actions"
                          options={actionOptions}
                          isDisabled={!canOpenActionsMenu}
                          defaultValue={{ label: "Action", value: "Action" }}
                          onChange={(value) =>
                            onChangeActionMenu(value as ActionMenuType)
                          }
                        />
                      )}
                    </FormControl>
                  </Flex>
                )}
              </Box>
            )}
          </GridItem>
          <GridItem
            colSpan={[8, 8, 4, 4]}
            display="flex"
            flexDirection="row"
            justifyContent={["left", "left", "right", "right"]}
            alignItems="flex-end"
          >
            <StatusChangeButtons
              transition={transition}
              request={request}
              onStatusChange={handleStatusChange}
              canReopen={canReopen}
            />
          </GridItem>
        </Grid>
        <Divider />

        {!isInit && <RequestFormSkeleton />}

        {isInit && (!request || !workflow) && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle mr={2}>
              You don't have policies to access to this request
            </AlertTitle>
          </Alert>
        )}

        {isInit && (
          <HStack py={3} overflowX={["scroll", "unset"]}>
            <ScrollToSectionButton
              name={Section.taskbooks}
              domRef={taskbookSectionRef}
              isSectionVisible={isTaskbooksVisible}
              updateVisibility={updateTaskbookVisibility}
              fieldDomRef={taskbooksFieldRef}
            />
            <ScrollToSectionButton
              name={Section.assets}
              domRef={assetsSectionRef}
              isSectionVisible={isAssetsVisible}
              updateVisibility={updateAssetsVisibility}
              fieldDomRef={assetsFieldRef}
            />
            <ScrollToSectionButton
              name={Section.labor}
              domRef={laborSectionRef}
              isSectionVisible={isLaborVisible}
              updateVisibility={updateLaborVisibility}
            />
            <ScrollToSectionButton
              name={Section.expenses}
              domRef={expensesSectionRef}
              isSectionVisible={isExpensesVisible}
              updateVisibility={updateExpensesVisibility}
            />
            {hasInventory && (
              <ScrollToSectionButton
                name={Section.inventory}
                domRef={inventorySectionRef}
                isSectionVisible={isInventoryVisible}
                updateVisibility={updateInventoryVisibility}
              />
            )}
          </HStack>
        )}

        {request && workflow && (
          <Grid
            templateColumns={["repeat(2, 1fr)", null, null, "repeat(12, 1fr)"]}
            verticalAlign="center"
            mb={2}
            gap={10}
          >
            {formIsLocked && (
              <GridItem colSpan={[2, null, null, 12]}>
                <Alert status="info">
                  <AlertIcon />
                  This request can no longer be edited.
                </Alert>
              </GridItem>
            )}
            <GridItem colSpan={[2, null, null, 7]}>
              <Grid
                templateColumns={"repeat(12, 1fr)"}
                verticalAlign="center"
                mb={2}
                gap={6}
              >
                <GridItem colSpan={[12]}>
                  <FormControl
                    id="requester"
                    display="inline-block"
                    width="max-content"
                    minWidth={[
                      "100%",
                      null,
                      "calc(50% - var(--chakra-space-4))",
                    ]}
                    maxW="100%"
                  >
                    <FormLabel>Requested by</FormLabel>
                    <RequesterAutocomplete
                      value={request.requester}
                      workflow={workflow}
                      location={request.location!}
                      onChange={handleRequesterChange}
                      isDisabled={formIsLocked || isContractorOnly}
                    />
                  </FormControl>
                </GridItem>
                {showAssignTo && (
                  <GridItem colSpan={12}>
                    <FormControl
                      id="assignee"
                      display="inline-block"
                      minWidth={[
                        "100%",
                        null,
                        "calc(50% - var(--chakra-space-4))",
                      ]}
                      maxW="100%"
                    >
                      <Flex justifyContent="space-between">
                        <FormLabel>Assigned To</FormLabel>
                        {showAssignToMe && (
                          <Link color="blue.400" onClick={handleAssignToMe}>
                            Assign to me
                          </Link>
                        )}
                      </Flex>
                      <AssigneeAutocomplete
                        value={request.assignees}
                        onChange={handleAssigneeChange}
                        workflow={workflow}
                        location={request.location!}
                        reportingCategory={
                          request.reportingCategory ?? undefined
                        }
                        isDisabled={
                          isRequesterOnly ||
                          isApproverOnly ||
                          isContractorOnly ||
                          isContractor ||
                          formIsLocked
                        }
                      />
                    </FormControl>
                  </GridItem>
                )}
                {showProject && (
                  <GridItem colSpan={[12, null, 12]}>
                    <FormControl id="projectId">
                      <FormLabel>Project</FormLabel>
                      <ProjectAutocomplete
                        value={project}
                        onChange={handleProjectChange}
                        isDisabled={formIsLocked}
                        isClearable={true}
                      />
                    </FormControl>
                  </GridItem>
                )}
                <GridItem colSpan={[12, null, 12]}>
                  <FormControl id="location">
                    <FormLabel>Location</FormLabel>
                    <LocationAutocomplete
                      onChange={handleLocationChange}
                      value={request.location}
                      allowEmpty={false}
                      isDisabled={formIsLocked || isContractorOnly}
                      allowedLocations={allowedLocations}
                      productName={Products.HeroHQ}
                    />
                    {request.location &&
                      (typeof request.location === "string"
                        ? !locationMap[request.location].active
                        : !request.location.active) && (
                        <Text as="span" fontSize="14px" color={errorColor}>
                          Current location is inactive. Please, change location
                        </Text>
                      )}
                  </FormControl>
                </GridItem>

                {locationGallery.gallery !== null && (
                  <GridItem
                    gap={4}
                    display="flex"
                    flexDir="column"
                    colSpan={[12, null, 12]}
                  >
                    <Text
                      gap={2}
                      display="flex"
                      w="max-content"
                      cursor="pointer"
                      alignItems="center"
                      fontWeight="semibold"
                      onClick={handleOnClickLocationsGallery}
                    >
                      {`Gallery Images(${locationGallery.gallery.totalAttachments})`}
                      <Icon
                        as={
                          locationGallery.isOpen
                            ? MdKeyboardArrowUp
                            : MdKeyboardArrowDown
                        }
                      />
                    </Text>

                    <Collapse in={locationGallery.isOpen}>
                      <Flex flexDir="column" gap={4} mx={2}>
                        <Grid templateColumns="repeat(6, 1fr)" gap={4} mb={2}>
                          {locationGallery.images.map((att) => (
                            <GridItem colSpan={[6, 6, 3]}>
                              <GalleryImageCard attachment={att} />
                            </GridItem>
                          ))}
                        </Grid>

                        {locationGallery.imagesTotal > 0 && (
                          <Pager
                            showDetails
                            total={locationGallery.imagesTotal}
                            currentPage={locationGallery.imagesPage}
                            pageSize={locationGallery.imagesPageSize}
                            onPageChange={handleOnGalleryPageChange}
                          />
                        )}
                      </Flex>
                    </Collapse>
                  </GridItem>
                )}

                {(showCategory ||
                  showReason ||
                  request.reportingCategory !== null ||
                  request.reason !== null) && (
                  <>
                    {(showCategory || request.reportingCategory !== null) && (
                      <GridItem colSpan={[12, null, 6]}>
                        <FormControl id="reporting-category">
                          <FormLabel flexDirection="row">
                            Category
                            {request.reportingCategory == null &&
                              request.categoryPredictions &&
                              request.categoryPredictions.length > 0 && (
                                <Tooltip label="Category has AI suggestions available">
                                  <Box display="inline-block">
                                    <Icon
                                      as={HiOutlineSparkles}
                                      ml={2}
                                      boxSize="1.1em"
                                      className="ai-sparkle-animation"
                                    />
                                  </Box>
                                </Tooltip>
                              )}
                          </FormLabel>
                          <ReportingCategoryAutocomplete
                            workflow={workflow}
                            value={request.reportingCategory}
                            onChange={handleCategoryChange}
                            isDisabled={
                              !showCategory || formIsLocked || isContractorOnly
                            }
                            predictedCategories={request.categoryPredictions}
                          />
                          {request.reportingCategory &&
                            !categoriesMap[request.reportingCategory.id]
                              ?.active && (
                              <Text
                                as="span"
                                fontSize="14px"
                                color={errorColor}
                              >
                                Current Category is inactive. Please, change
                                category
                              </Text>
                            )}
                        </FormControl>
                      </GridItem>
                    )}

                    {(showReason || request.reason !== null) && (
                      <GridItem colSpan={[12, null, 6]}>
                        <FormControl id="reason">
                          <FormLabel>Reason</FormLabel>
                          <ReasonAutocomplete
                            workflow={workflow}
                            value={request.reason}
                            onChange={handleReasonChange}
                            isDisabled={
                              !showReason || formIsLocked || isContractorOnly
                            }
                          />
                          {request.reason &&
                            !reasonsMap[request.reason.id]?.active && (
                              <Text
                                as="span"
                                fontSize="14px"
                                color={errorColor}
                              >
                                Current Reason is inactive. Please, change
                                reason
                              </Text>
                            )}
                        </FormControl>
                      </GridItem>
                    )}
                  </>
                )}

                <GridItem colSpan={[12, null, 12]}>
                  <RichTextEditorComments
                    id="comment-edit"
                    value={summary}
                    label={"Description/Summary"}
                    onBlur={handleSummaryBlur}
                    isInvalid={isSummaryInvalid}
                    invalidMessage={
                      isSummaryInvalid ? "Summary is required" : undefined
                    }
                    autosize={true}
                  />
                </GridItem>
                {orderedVisibleFields && orderedVisibleFields.length ? (
                  <GridItem colSpan={[12]}>
                    <Grid templateColumns={["repeat(2, 1fr)"]} columnGap={6}>
                      {orderedVisibleFields.map((field) => (
                        <GridItem
                          colSpan={
                            field.isFullWidth ? [2, null, 2] : [2, null, 1]
                          }
                          key={field.id}
                          display="flex"
                          alignItems="flex-end"
                          pb={3}
                        >
                          <CustomFieldInput
                            field={field.field}
                            value={request.metadata[field.field.key]}
                            onChange={handleCustomFieldChange(field.field)}
                            isDisabled={formIsLocked || isContractorOnly}
                            name="request-custom-field"
                          />
                        </GridItem>
                      ))}
                    </Grid>
                  </GridItem>
                ) : null}

                {(showStartDate || request.scheduling?.start) && (
                  <GridItem colSpan={[12, 12, 6]}>
                    <FormControl id="startDate" mb={2}>
                      <FormLabel>Start</FormLabel>
                      <StyledDatePicker
                        value={request.scheduling?.start || null}
                        onChange={handleStartDateChange}
                        showTime={true}
                        isDisabled={formIsLocked || isContractorOnly}
                        name="request-start-date"
                      />
                    </FormControl>
                  </GridItem>
                )}
                {(showDueDate || request.scheduling?.due) && (
                  <GridItem colSpan={[12, 12, 6]}>
                    <FormControl id="dueDate" mb={2}>
                      <Flex>
                        <FormLabel>Due</FormLabel>
                        {isOverdue && request.scheduling.due && (
                          <Flex
                            alignItems="center"
                            color={overdueWarningColor}
                            ml="auto"
                          >
                            <ImWarning />
                            <Text ml={2}>
                              {" "}
                              Overdue for{" "}
                              {formatDistanceToNow(
                                new Date(
                                  request.scheduling.due.toLocaleString()
                                )
                              )}
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                      <StyledDatePicker
                        value={request.scheduling?.due || null}
                        onChange={handleDueDateChange}
                        showTime={true}
                        isDisabled={formIsLocked || isContractorOnly}
                        name="request-due-date"
                      />
                    </FormControl>
                  </GridItem>
                )}

                <GridItem colSpan={[12, null, null, 12]} pt={4}>
                  <Divider />
                </GridItem>
                <GridItem colSpan={[12, null, 12]}>
                  <Attachments
                    attachments={attachments}
                    onDeleteAttachment={handleDeleteAttachment}
                    onNewAttachments={handleNewAttachments}
                    isDisabled={
                      formIsLocked && request.status !== ApiRequestStatus.closed
                    }
                    displayActions={isContractorOnly === false}
                  />
                </GridItem>

                <FormSection
                  name="taskboks"
                  isVisible={
                    (showTaskbooks || taskbooks.length > 0) &&
                    isTaskbooksVisible
                  }
                  ref={taskbookSectionRef}
                >
                  <TaskbooksSection
                    fieldRef={taskbooksFieldRef}
                    requestEngine={engine}
                    isTaskbooksRendered={isTaskbooksRendered}
                    updateTaskbooksRenderState={updateTaskbooksRenderState}
                  />
                </FormSection>
                {request.scheduledRequestId && (
                  <>
                    <GridItem colSpan={[12, null, null, 12]} pt={4}>
                      <Divider />
                    </GridItem>
                    <ScheduledRequestSection
                      scheduledRequestId={request.scheduledRequestId}
                    />
                  </>
                )}
                {request.eventId && (
                  <>
                    <GridItem colSpan={[12, null, null, 12]} pt={4}>
                      <Divider />
                    </GridItem>
                    <GridItem
                      gap={4}
                      colSpan={12}
                      display="flex"
                      flexDir="column"
                    >
                      <Heading size="md">Event</Heading>
                      <Alert status="info" borderRadius={6}>
                        <AlertIcon />
                        This request was generated from the following event
                      </Alert>
                      <EventBasicDetails event={request.eventId} />
                    </GridItem>
                  </>
                )}

                {/* <GridItem> is inside this section since the render condition is also inside */}
                <RequestAssociationList />
                {/* </GridItem> */}

                {/* costs */}
                {canSeeCostSection && !isRequesterOnly && !isApproverOnly && (
                  <>
                    <GridItem colSpan={[12, null, null, 12]} pt={4}>
                      <Divider />
                    </GridItem>
                    <GridItem colSpan={[12, null, 12]}>
                      <CostSection
                        handlePropertyChange={handlePropertyChange}
                      />
                    </GridItem>
                  </>
                )}

                <FormSection
                  name="assets"
                  isVisible={isAssetsVisible}
                  ref={assetsSectionRef}
                >
                  <AssetSection
                    fieldRef={assetsFieldRef}
                    isAssetRendered={isAssetsRendered}
                    updateAssetRendered={updateAssetsRenderState}
                    requestLocation={request.location ?? undefined}
                    reportingCategory={request.reportingCategory ?? undefined}
                  />
                </FormSection>
                <FormSection
                  name="labor"
                  isVisible={
                    !isRequesterOnly && !isApproverOnly && isLaborVisible
                  }
                  ref={laborSectionRef}
                >
                  <LaborsSection />
                  {showLabor &&
                    showEstimatedLabor &&
                    !isRequesterOnly &&
                    !isApproverOnly &&
                    !isContractorOnly &&
                    !isContractor && (
                      <EstimateLabors
                        handlePropertyChange={handlePropertyChange}
                      />
                    )}
                  {transactions.totalHours ? (
                    <Flex mt={3}>
                      <Text textAlign="left" fontWeight="bold">
                        Total Labor:
                      </Text>
                      <Spacer />
                      <Text textAlign="right" fontWeight="bold">
                        {getHourAndMinutesNotation(transactions.totalHours)}
                      </Text>
                    </Flex>
                  ) : null}
                </FormSection>
                <FormSection
                  name="expenses"
                  isVisible={
                    !isRequesterOnly && !isApproverOnly && isExpensesVisible
                  }
                  ref={expensesSectionRef}
                >
                  <VStack gap={2} alignItems="stretch">
                    <PurchasesSection />

                    {showExpenses &&
                      showEstimatedCost &&
                      !isRequesterOnly &&
                      !isApproverOnly &&
                      !isContractorOnly &&
                      !isContractor && (
                        <EstimatedExpenses
                          handlePropertyChange={handlePropertyChange}
                        />
                      )}
                    {transactions.totalPurchases ? (
                      <Flex alignItems="flex-end">
                        <Text textAlign="left" fontWeight="bold">
                          Total Cost Expenses:
                        </Text>
                        <Spacer />
                        <Text textAlign="right" fontWeight="bold">
                          {getMoneyNotation(transactions.totalPurchases)}
                        </Text>
                      </Flex>
                    ) : null}
                  </VStack>
                </FormSection>

                <FormSection
                  name="inventory"
                  isVisible={request && isInventoryVisible}
                  ref={inventorySectionRef}
                >
                  {canViewInventoryRequest && (
                    <GridItem colSpan={[12, null, 12]}>
                      <InventoryRequests
                        request={request}
                        canCreateInventoryRequest={canCreateInventoryRequest}
                      />
                    </GridItem>
                  )}

                  {canViewInventoryIssuance && (
                    <GridItem colSpan={[12, null, 12]}>
                      <InventoryIssuance
                        request={request}
                        canCreateInventoryIssuance={canCreateInventoryIssuance}
                      />
                    </GridItem>
                  )}
                </FormSection>

                {showHoursAndCost && (
                  <GridItem
                    display={["block", "block", "block", "none"]}
                    colSpan={12}
                  >
                    <HoursCostSection
                      onPrint={printReportOnOpen}
                      totalHours={transactions.totalHours}
                      expensesCost={totalCost.expenses}
                      laborCost={totalCost.laborsTotalCost}
                      inventoryCost={totalCost.inventoryTotalCost}
                    />
                  </GridItem>
                )}

                {showComments && (
                  <GridItem
                    display={["block", "block", "block", "none"]}
                    colSpan={12}
                  >
                    <CommentsSection />
                  </GridItem>
                )}

                {isInit && (
                  <>
                    <GridItem colSpan={[12, null, null, 12]} pt={4}>
                      <Divider />
                    </GridItem>
                    <GridItem colSpan={12}>
                      <TimeLine handlePropertyChange={handlePropertyChange} />
                    </GridItem>
                    <GridItem colSpan={[12, null, null, 12]} pt={4}>
                      <Divider />
                    </GridItem>
                    {(isReviewer || isAdmin || isProductAdmin) && (
                      <GridItem colSpan={[12, null, 12]}>
                        <Suspense fallback={<Spinner />}>
                          <CompleteRequestTimeline />
                        </Suspense>
                      </GridItem>
                    )}
                  </>
                )}
                {isInit && !isRequesterOnly && !isApproverOnly && (
                  <>
                    <GridItem colSpan={[12, null, null, 12]} pt={4}>
                      <Divider />
                    </GridItem>
                    <GridItem colSpan={[12, null, 12]}>
                      <Suspense fallback={<Spinner />}>
                        <RequestNotification />
                      </Suspense>
                    </GridItem>
                  </>
                )}
              </Grid>
            </GridItem>
            <GridItem display={["none", "none", "none", "block"]} colSpan={5}>
              {showHoursAndCost && (
                <HoursCostSection
                  onPrint={printReportOnOpen}
                  totalHours={transactions.totalHours}
                  expensesCost={totalCost.expenses}
                  laborCost={totalCost.laborsTotalCost}
                  inventoryCost={totalCost.inventoryTotalCost}
                />
              )}
              {showComments && <CommentsSection />}
            </GridItem>
          </Grid>
        )}
      </Stack>

      {taskBooksCompleted &&
      request &&
      (changeModal.changeVerification !== null ||
        changeModal.delta !== null) ? (
        <ChangeModal
          isOpen={
            taskBooksCompleted &&
            (changeModal.changeVerification !== null ||
              changeModal.delta !== null)
          }
          onSave={handleChangeModalSave}
          onCancel={handleChangeModalCancel}
          isSaving={isChangeModalSaving}
        />
      ) : null}

      {request && menuActionValue !== null && isOpen && (
        <MoveRequestModal
          isOpen={isOpen}
          onClose={onClose}
          menuActionValue={menuActionValue}
        />
      )}

      {request && workflow && printIsOpen && (
        <PrintRequestModal
          isOpen={printIsOpen}
          onClose={printOnClose}
          request={request}
          visibleFields={visibleFields.filter(
            (field) =>
              field.dataType !== ApiWorkflowFieldDataType.system ||
              field.key === "SYSTEM-START-DATE" ||
              field.key === "SYSTEM-DUE-DATE"
          )}
          account={currentAccount}
          format="full"
          comments={commentsToPrint}
          workflow={workflow}
        />
      )}

      {request && printReportIsOpen && (
        <PrintReportModal
          account={currentAccount}
          isOpen={printReportIsOpen}
          onClose={printReportOnClose}
          request={request}
          visibleFields={visibleFields.filter(
            (field) =>
              field.dataType !== ApiWorkflowFieldDataType.system ||
              field.key === "SYSTEM-START-DATE" ||
              field.key === "SYSTEM-DUE-DATE"
          )}
          totalLabor={getHourAndMinutesNotation(transactions.totalHours)}
        />
      )}

      {request && isOpenAssociation && (
        <AssociationRequestsModal
          isOpen={isOpenAssociation}
          onClose={onCloseAssociation}
        />
      )}
    </Container>
  );
};
